:root {
  --tg-theme-button-color: #D8B365 !important;
  --tg-theme-link-color: #D8B365 !important;
  --tgui--secondary_fill: #D8B365 !important;
  --tg-theme-accent-text-color: #D8B365 !important;
  /* Новый цвет */
}

body {
  background-color: #151515;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo2 {
  width: 80px;
  padding: 1rem 0rem 0rem 1rem;
}